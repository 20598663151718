export const whatsapp_broadcasts_apis = {
  list_campaigns: "notifications/whatsapp/campaigns/list",
  update_campaign: "notifications/whatsapp/campaign/update",
  detail_campaigns: "notifications/whatsapp/campaign/details",
  sync_campaign: "notifications/whatsapp/campaign/sync",
  create_campaign: "notifications/whatsapp/campaign/create",
  list_campaign_audiences: "notifications/whatsapp/audience/list",
  list_categories: "notifications/wa/categories/list",
  list_category_templates: "notifications/wa/templates/list",
  list_audience_groups: "notifications/whatsapp/audience/paginated/list/v2",
  list_custom_creator_templates: "notifications/wa/templates/custom/list",
  create_new_template: "notifications/wa/templates/custom/create",
  update_template: "notifications/wa/templates/custom/update",
  delete_custom_template: "notifications/wa/templates/custom/delete",
  get_templates: "notifications/wa/template/custom/details",
  delete_campaign: "notifications/whatsapp/campaign/delete",
  get_whatsapp_white_label_details:
    "notifications/wa/whitelabel/whatsapp/details",
  get_meta_url: "notifications/wa/whitelabel/embedded/signup/link",
  submit_green_tick_application: "notifications/wa/whitelabel/greentick/form",
};
