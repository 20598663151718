import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import styles from "./style.module.css";
import getCroppedImg from "./crop";

export const StyledDemo = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.src,
        croppedAreaPixels
      );
      setCroppedImage(croppedImage);
      props.closeModal(croppedImage,  sessionStorage.getItem('canvaimg'));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <>
      <div className={`alert_modal ${styles.cropmodalcontainer}`}>
        <div className={styles.headingcropper}>
          You Have Changed Template Please Reposition Or Change Your Cover Photo
        </div>
        <div className={styles.cropContainer}>
          <Cropper
            image={props.src}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            className={styles.cropper}
          />
          <div className={styles.dragContainer}>
            <img src={require("../../../assets/images/drag.svg")} />
            <div>Drag to reposition cover photo</div>
          </div>
        </div>
        <div className={styles.cropcontrol}>
          <div className={styles.zoomcrop}>
            <div className={styles.zoomText}>
              Zoom
            </div>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <div className={`${styles.openCloseModal} ${styles.croppercontainerbtn}`}>
            {props.isUploading ? null : (
              <div
                onClick={props.closeModalWithoutSaving}
                className={styles.cancelText}
              >
                Cancel
              </div>
            )}

            {props.isUploading ? (
              <div
                className={`${styles.cropText} ${styles.horizontalContainer} ${styles.cropperbutton}`}
              >
                <span>Uploading</span>
                <div className={styles.loader}> </div>
              </div>
            ) : (
              <div onClick={showCroppedImage} className={`${styles.cropText} ${styles.cropperbutton}`}>
                <span>Crop Image</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
