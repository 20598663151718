import React, { PureComponent } from "react";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./style.module.css";
import Drawer from "@material-ui/core/Drawer";
import RightPanel from "./RightPanel";

import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { is_empty } from "../../../utils/validations";

/**
 * @deprecated use src/ui/widgets/photoUploader/PhotoWidgetV2.js
 */
class PhotoWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.htmlId = `widget_image${props.id ? `-${props.id}` : ""}`;

    this.ref = React.createRef();
    this.state = {
      hover: false,
    };
  }

  state = {
    src: "",
    crop: {
      unit: "%",
      aspect: 16 / 9,
      width: 1000,
    },
    showModal: false,
    isNewImageUpload: false,
    modalState: false,
    imageWidth: 100,
    imageHeight: 100,
    borderRadius: "50%",
    minWidth: 160,
    minHeight: 100,
    croppedFile: "",
    openDrawer: false,
    photoUrl: "",
  };

  componentDidMount() {
    this.setState({
      src: this.props.image,
      original: this.props.image,
      zoom: this.props.zoom,
      crop: this.props.crop,
      showModal: false,
      isNewImageUpload: false,
      modalState: false,
      imageWidth: this.props.width,
      imageHeight: this.props.height,
      borderRadius: this.props.radius,
      minWidth: this.props.minWidth,
      minHeight: this.props.minHeight,
    });
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  setDrawer = () => {
    if (this.state.openDrawer) {
      this.setState({ openDrawer: false });
    } else {
      this.setState({ openDrawer: true });
    }
    this.drawerHandling();
  };

  drawerHandling = () => {
    if (this.props.handleDrawer) {
      this.props.handleDrawer(false);
      this.props.setExistingImage("");
      this.setState({ openDrawer: false });
    } else if (this.state.openDrawer) {
      this.setState({ openDrawer: false });
    }
  };

  setPhotoUrl = (url) => {
    this.props.getUrl(url);
    this.setState({ isNewImageUpload: true });
    this.setState({ croppedImageUrl: url });
    this.setDrawer();
  };

  render() {
    const {
      original,
      crop,
      croppedImageUrl,
      src,
      imageWidth,
      imageHeight,
      borderRadius,
      openDrawer,
    } = this.state;

    let img_width =
      !is_empty(crop) && !is_empty(crop.aspect)
        ? imageHeight * crop.aspect
        : imageWidth;
    let img_height = imageHeight;

    if (is_empty(img_width) || is_empty(img_height)) return null;

    if (
      document.querySelector(`#${this.htmlId}`) &&
      img_width >
        document.querySelector(`#${this.htmlId}`).parentElement.parentElement
          .offsetWidth
    ) {
      img_width = document.querySelector(`#${this.htmlId}`).parentElement
        .parentElement.offsetWidth;
      // this.setState({imageHeight: !is_empty(crop) && !is_empty(crop.aspect) ? imageWidth / crop.aspect : imageHeight});
      img_height =
        !is_empty(crop) && !is_empty(crop.aspect)
          ? img_width / crop.aspect
          : imageHeight;
    }

    return (
      <div
        id={"photo_widget_container"}
        className={this.props.className}
        style={
          this.props.from !== "congratulatepopup"
            ? {
                width: imageWidth,
                height: imageHeight,
                borderRadius: borderRadius,
              }
            : { marginBottom: "10px" }
        }
      >
        {this.props.from !== "congratulatepopup" ? (
          <div
            id={"cover_image"}
            style={{
              width: imageWidth,
              height: imageHeight,
              borderRadius: borderRadius,
            }}
            onClick={this.setDrawer}
          >
            <div
              className={styles.container}
              style={{
                width:
                  !is_empty(crop) && !is_empty(crop.aspect)
                    ? imageHeight * crop.aspect
                    : imageWidth,
                height: imageHeight,
                borderRadius: borderRadius,
              }}
            >
              <ExlyImage
                id={this.htmlId}
                src={
                  !this.state.isNewImageUpload
                    ? original
                    : croppedImageUrl
                    ? croppedImageUrl
                    : { src }
                }
                fetchWidth={img_width}
                width={img_width}
                alt={!this.state.isNewImageUpload ? "cropped" : "original"}
                style={{
                  width: img_width,
                  height: img_height,
                  borderRadius: borderRadius,
                }}
                className={styles.imagePreview}
              />
              <div
                className={styles.middle}
                style={{
                  width: img_width,
                  height: img_height,
                  borderRadius: borderRadius,
                }}
              >
                <CameraAltIcon style={{ color: "white", fontSize: 32 }} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {(this.props.openDrawer || openDrawer) && (
          <Drawer
            open
            anchor="right"
            ref={this.ref}
            variant="temporary"
            onEscapeKeyDown={() => this.drawerHandling()}
            onBackdropClick={() => this.drawerHandling()}
          >
            <RightPanel
              fluidAspectRatio={this.props.fluidAspectRatio}
              query={this.props.query}
              minWidth={this.props.minWidth}
              minHeight={this.props.minHeight}
              id="photo"
              radius={this.props.radius}
              from={this.props.from}
              imageexits={this.props.imageexits}
              existingimage={this.props.existingimage}
              crop={crop}
              width={this.props.width}
              height={this.props.height}
              image={original}
              getUrl={(url) => {
                this.setPhotoUrl(url);
              }}
              uploadViaOptimization={this.props.uploadViaOptimization}
              sectionName={this.props.sectionName}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default PhotoWidget;
