import {
  FETCH_APPROVED_TEMPLATES,
  FETCH_APPROVED_TEMPLATES_FAILURE,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORY_TEMPLATE,
  FETCH_CATEGORY_TEMPLATE_FAILURE,
  FETCH_WHATSAPP_CAMPAIGN,
  RESET_TEMPLATE_VALUES,
  SET_APPROVED_TEMPLATES,
  SET_AUDIENCE_DATA,
  SET_CAMPAIGN_TYPE,
  SET_CATEGORIES,
  SET_CATEGORY_TEMPLATE,
  SET_IS_CUSTOM_TEMPLATE,
  SET_TEMPLATE_ACTION_TYPE,
  SET_TEMPLATE_VALUES,
  SET_WHATSAPP_CAMPAIGN_DATA,
  SET_WHATSAPP_CAMPAIGN_DATA_FAILURE,
} from "./actions";

const initialState = {
  /**
   * the intial value of selectedTemplate will have one key isCustomTemplate which will be false becuase
   * an empty template can never be a custom template
   *  */
  selectedTemplate: {
    isCustomTemplate: false,
  },
  templateActionType: null,
  categories: [],
  categoryTemplates: {},
  approvedTemplates: [],
  loading: false,
  error: false,
  errorMessage: "",
  audience: null,
  whatsappCampaignData: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEMPLATE_VALUES: {
      // Setting custom template value to that it doesnt get lost
      payload = {
        ...payload,
        isCustomTemplate: state?.selectedTemplate?.isCustomTemplate || false,
      };
      return { ...state, selectedTemplate: { ...payload } };
    }
    case RESET_TEMPLATE_VALUES: {
      return {
        ...state,
        selectedTemplate: {
          isCustomTemplate: false,
        },
      };
    }
    case SET_TEMPLATE_ACTION_TYPE: {
      return { ...state, templateActionType: payload };
    }
    case SET_CAMPAIGN_TYPE: {
      return { ...state, campaignType: payload };
    }
    case SET_IS_CUSTOM_TEMPLATE:
      /**
       * Having a separate action for setting custom template field
       * So that we can set it directly without worrying about the other fields in template
       *  */

      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          isCustomTemplate: payload,
        },
      };
    case FETCH_CATEGORIES:
    case FETCH_APPROVED_TEMPLATES:
    case FETCH_CATEGORY_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
      };
    case SET_APPROVED_TEMPLATES:
      return {
        ...state,
        approvedTemplates: payload,
        loading: false,
      };
    case SET_CATEGORY_TEMPLATE:
      return {
        ...state,
        categoryTemplates: {
          ...state.categoryTemplates,
          [payload.itemKey]: payload.templates,
        },
        loading: false,
      };
    case FETCH_CATEGORIES_FAILURE:
    case FETCH_APPROVED_TEMPLATES_FAILURE:
    case FETCH_CATEGORY_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: payload?.message || "some error occurred ",
      };
    case SET_AUDIENCE_DATA:
      return {
        ...state,
        audience: payload,
      };
    case FETCH_WHATSAPP_CAMPAIGN:
      return {
        ...state,
        payload,
        loading: true,
      };
    case SET_WHATSAPP_CAMPAIGN_DATA:
      return {
        ...state,
        whatsappCampaignData: payload,
        loading: false,
      };
    case SET_WHATSAPP_CAMPAIGN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: payload?.message || "Error in getting campaign details",
      };
    default: {
      return state;
    }
  }
};

export default reducer;
