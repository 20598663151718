import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_APPROVED_TEMPLATES,
  FETCH_CATEGORY_TEMPLATE,
  FETCH_APPROVED_TEMPLATES_FAILURE,
  SET_APPROVED_TEMPLATES,
  SET_CATEGORY_TEMPLATE,
  FETCH_CATEGORY_TEMPLATE_FAILURE,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILURE,
  SET_CATEGORIES,
  FETCH_WHATSAPP_CAMPAIGN,
  SET_WHATSAPP_CAMPAIGN_DATA,
  SET_WHATSAPP_CAMPAIGN_DATA_FAILURE,
} from "./actions";
import { whatsapp_broadcasts_apis } from "../whatsappBroadCast.api";
import { whatsappSubTabsMapping } from "../list/constants";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";

function* fetchCategoriesSaga() {
  try {
    const { data, status } = yield call(
      dataProvider.custom_request,
      whatsapp_broadcasts_apis.list_categories,
      apiMethods.GET
    );

    if (status !== 200) {
      yield put({ type: FETCH_CATEGORIES_FAILURE });
      return;
    }
    yield put({ type: SET_CATEGORIES, payload: data.campaigns });
  } catch (error) {
    yield put({ type: FETCH_CATEGORIES_FAILURE, payload: error });
  }
}

function* fetchApprovedTemplatesSaga() {
  try {
    const { data, status } = yield call(
      dataProvider.custom_request,
      whatsapp_broadcasts_apis.list_custom_creator_templates,
      apiMethods.GET,
      { approval_status: whatsappSubTabsMapping.Approved }
    );

    if (status !== 200) {
      yield put({ type: FETCH_APPROVED_TEMPLATES_FAILURE });
      return;
    }
    yield put({
      type: SET_APPROVED_TEMPLATES,
      payload: data?.custom_templates,
    });
  } catch (error) {
    yield put({ type: FETCH_APPROVED_TEMPLATES_FAILURE, payload: error });
  }
}

function* fetchCategoryTemplateSaga(action) {
  const { itemKey } = action.payload;

  try {
    const { data, status } = yield call(
      dataProvider.custom_request,
      whatsapp_broadcasts_apis.list_category_templates,
      apiMethods.GET,
      { category_uid: itemKey }
    );

    if (status !== 200) {
      yield put({ type: FETCH_CATEGORY_TEMPLATE_FAILURE });
      return;
    }

    yield put({
      type: SET_CATEGORY_TEMPLATE,
      payload: { itemKey, templates: data.templates },
    });
  } catch (error) {
    yield put({ type: FETCH_CATEGORY_TEMPLATE_FAILURE, payload: error });
  }
}

function* fetchWhatsappCampaignDataSaga({ payload = {} }) {
  const { isSpecificListing, campaign_uid, specificListingParams } = payload;
  let data = null,
    response = null;
  try {
    if (isSpecificListing) {
      //get whatsapp drip data
      response = yield call(
        dataProvider.custom_request,
        api.listingSpecificCommunication.get_whatsapp_template_data +
          specificListingParams.listing_comm_uuid
      );
      data = { ...specificListingParams, ...response?.data };
    } else {
      // get Broadcast data
      response = yield call(
        dataProvider.custom_request,
        whatsapp_broadcasts_apis.detail_campaigns,
        apiMethods.GET,
        { campaign_uid }
      );
      data = response.data;
    }
    if (data?.listing_uuid) {
      const listingResponse = yield call(
        dataProvider.custom_request,
        `${api.get_listing_details}/${data?.listing_uuid}`,
        apiMethods.GET
      );
      data.selectedListing = listingResponse?.data.event;
    }
    yield put({ type: SET_WHATSAPP_CAMPAIGN_DATA, payload: data });
  } catch (error) {
    yield put({ type: SET_WHATSAPP_CAMPAIGN_DATA_FAILURE, payload: error });
  }
}

function* whatsappTemplateSaga() {
  yield takeEvery(FETCH_CATEGORIES, fetchCategoriesSaga);

  yield takeEvery(FETCH_APPROVED_TEMPLATES, fetchApprovedTemplatesSaga);
  yield takeEvery(FETCH_CATEGORY_TEMPLATE, fetchCategoryTemplateSaga);
  yield takeEvery(FETCH_WHATSAPP_CAMPAIGN, fetchWhatsappCampaignDataSaga);
}

export default whatsappTemplateSaga;
