import WhatsappGuide01 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_01.svg";
import WhatsappGuide02 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_02.svg";
import WhatsappGuide03 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_03.svg";
import WhatsappGuide04 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_04.svg";
import WhatsappGuide05 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_05.svg";
import WhatsappGuide06 from "assets/vectors/whatsap-broadcast/whatsapp_guide_desktop_06.svg";
import WhatsappGuideMobile01 from "assets/vectors/whatsap-broadcast/whatsapp_guide_mobile_01.svg";
import WhatsappGuideMobile02 from "assets/images/whatsap-broadcast/whatsapp_guide_mobile_02.png";
import WhatsappGuideMobile03 from "assets/images/whatsap-broadcast/whatsapp_guide_mobile_03.png";
import WhatsappGuideMobile04 from "assets/images/whatsap-broadcast/whatsapp_guide_mobile_04.png";
import WhatsappGuideMobile05 from "assets/images/whatsap-broadcast/whatsapp_guide_mobile_05.png";
import WhatsappGuideMobile06 from "assets/images/whatsap-broadcast/whatsapp_guide_mobile_06.png";

import React from "react";
import classnames from "classnames";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { WHATSAPP_BROADCASTS_LEARN_MORE_HREFS } from "../whatsappBroadcast.constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
export const whatsappTabsMapping = {
  broadcast: 1,
  templates: 2,
};

export const whatsappSubTabsMapping = {
  Approved: 4,
  Pending: 2,
  Rejected: 5,
  Drafts: 1,
};

export const whatsappCampaignStatusChoices = {
  Completed: 4,
  Scheduled: 2,
  Paused: 5,
  Drafts: 1,
  Active: 3,
  Deleted: 6,
};

export const whatsappTabsValue = [
  {
    value: whatsappTabsMapping.broadcast,
    label: "Broadcasts",
  },
  {
    value: whatsappTabsMapping.templates,
    label: "My Templates",
  },
];

export const whatsappSubTabsValue = {
  [whatsappSubTabsMapping.Approved]: {
    Position: 1,
    value: whatsappSubTabsMapping.Approved,
    label: "Approved",
  },
  [whatsappSubTabsMapping.Pending]: {
    Position: 2,
    value: whatsappSubTabsMapping.Pending,
    label: "Pending",
  },
  [whatsappSubTabsMapping.Rejected]: {
    Position: 3,
    value: whatsappSubTabsMapping.Rejected,
    label: "Rejected",
  },
  [whatsappSubTabsMapping.Drafts]: {
    Position: 4,
    value: whatsappSubTabsMapping.Drafts,
    label: "Drafts",
  },
};

export const whatsappBroadCaseSubTabsValue = {
  [whatsappCampaignStatusChoices.Completed]: {
    value: whatsappCampaignStatusChoices.Completed,
    label: "Broadcast History",
    Position: 1,
  },
  [whatsappCampaignStatusChoices.Scheduled]: {
    value: whatsappCampaignStatusChoices.Scheduled,
    label: "Scheduled",
    Position: 2,
  },
  [whatsappCampaignStatusChoices.Drafts]: {
    value: whatsappCampaignStatusChoices.Drafts,
    label: "Drafts",
    Position: 3,
  },
};

export const EmptyState = ({
  handleCreate,
  hasWriteAccess,
  subTabValue,
  autoplay,
  loading,
}) => {
  const emptyStateMap = {
    [whatsappCampaignStatusChoices.Scheduled]: {
      imgSrc:
        "/assets/vectors/sales_and_marketing/broadcast/scheduled_illustration.svg",
      title: "You have no broadcasts scheduled. Schedule one now.",
      description:
        "Broadcast messages to your leads or customers. Share your upcoming events, publish a newsletter, provide an important update or just send a greeting. View the video to learn more.",
    },
    [whatsappCampaignStatusChoices.Drafts]: {
      imgSrc:
        "/assets/vectors/sales_and_marketing/broadcast/draft_illustration.svg",
      title: "You have no drafts saved currently. Start drafting one.",
      description:
        "Send WhatsApp messages to your leads or customers. Share your upcoming events, publish a newsletter, provide an important update or just send a greeting. View the video to learn more.",
    },
    default: {
      title: "No lead messages yet",
      description:
        "The Broadcast feature allows you to send a message to several contacts at once. View the video to learn more.",
      videoSrc: WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS_VIDEO,
      playerProps: { playing: autoplay },
      primaryCtaProps: hasWriteAccess && {
        title: "Create WhatsApp Broadcast",
        onClick: handleCreate,
      },
    },
  };

  const emptyStateProps = {
    ...(emptyStateMap[subTabValue] || emptyStateMap.default),
    secondaryCtaProps: {
      variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
      learnMoreHref: WHATSAPP_BROADCASTS_LEARN_MORE_HREFS.WHATSAPP_BROADCASTS,
    },
    className: "mt-4",
  };

  return <ExlyEmptyState isLoading={loading} {...emptyStateProps} />;
};

export const whatsAppGuideSlides = (isDesktop) => [
  {
    title: "Create custom templates",
    icon: isDesktop ? WhatsappGuide01 : WhatsappGuideMobile01,
    description:
      "You can create your own personalised message templates that you can use for your WhatsApp broadcasts. Design a message and submit. Once Meta verifies your template, you’re good to go!",
  },
  {
    title: "Design your message",
    icon: isDesktop ? WhatsappGuide02 : WhatsappGuideMobile02,
    description:
      "You can easily design your message as per your requirements. But you have to make sure you are following the template guidelines from Meta. You can always refer to the guidelines through the link at the bottom.",
  },
  {
    title: "Personalise with variables",
    icon: isDesktop ? WhatsappGuide03 : WhatsappGuideMobile03,
    description:
      "Variables will help you automate details in your message. To add variables in your message: Go to Personalise in the bottom left, choose the offering name variable, and we will make sure the offering name gets added to your message.",
  },
  {
    title: "Easy adding and editing",
    icon: isDesktop ? WhatsappGuide04 : WhatsappGuideMobile04,
    description:
      "Tap on a variable to choose a different variable and replace the current one. You can simply hit Backspace to remove a variable. As simple as pie!",
  },
  {
    title: "Submit for approval",
    icon: isDesktop ? WhatsappGuide05 : WhatsappGuideMobile05,
    description:
      "Once you have your message template ready, send it to Meta for approval. It might take 30 mins to 2 business days for this verification (Subject to Meta team approval). Until verification completes, we mark it as Pending.",
  },
  {
    title: "What’s next",
    icon: isDesktop ? WhatsappGuide06 : WhatsappGuideMobile06,
    description:
      "OnceMeta completes verification, your message template may return as Approved or Rejected. It’s up to you to make sure that your message template follows the template guidelines in order to get it approved.",
  },
];

export const getStatusChip = ({ type, classes }) => {
  if (type === whatsappCampaignStatusChoices.Drafts)
    return (
      <div className={classnames(classes.draftChip, classes.chip)}>Draft</div>
    );

  if (type === whatsappCampaignStatusChoices.Scheduled)
    return (
      <div className={classnames(classes.scheduledChip, classes.chip)}>
        Scheduled
      </div>
    );

  return null;
};
