import React from "react";

import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";

const useStyles = makeStyles((theme) => ({
  modalRoot: {
    minWidth: "560px",
  },
  modalContentWrapper: {
    padding: ({ isDesktop }) => (isDesktop ? "24px" : "16px"),
  },
  headerContainer: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "29px",
      width: "29px",
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    paddingLeft: "1.2rem",
    "& li": {
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
}));

function LearnMoreModal({ title, icon, list, open, onClose }) {
  const isDesktop = window.screen.width > constants.mobile_width;
  const classes = useStyles({ isDesktop });

  const listing = (
    <ul className={classes.listItem}>
      {list.map((item, index) => (
        <li key={index} >{item}</li>
      ))}
    </ul>
  );

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalRoot}
      dialogContentClassName={classes.modalContentWrapper}
      open={open}
      onClose={onClose}
      title={
        <div className={classes.headerContainer}>
          {icon && <img src={icon} />}
          <span>{title}</span>
        </div>
      }
      customFooter={<></>}
    >
      {listing}
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header={
        <div className={classes.headerContainer}>
          {icon && <img src={icon} />}
          <span>{title}</span>
        </div>
      }
      customFooter={<></>}
    >
      <div className={classes.modalContentWrapper}>{listing}</div>
    </MobileModal>
  );
}

export default LearnMoreModal;
