export const SET_TEMPLATE_VALUES = "@SET_TEMPLATE_VALUES";
export const RESET_TEMPLATE_VALUES = "@RESET_TEMPLATE_VALUES";
export const SET_TEMPLATE_ACTION_TYPE = "@SET_TEMPLATE_ACTION_TYPE";
export const FETCH_APPROVED_TEMPLATES = "@FETCH_APPROVED_TEMPLATES";
export const FETCH_CATEGORY_TEMPLATE = "@FETCH_CATEGORY_TEMPLATE";
export const FETCH_APPROVED_TEMPLATES_FAILURE =
  "@FETCH_APPROVED_TEMPLATES_FAILURE";
export const SET_APPROVED_TEMPLATES = "@SET_APPROVED_TEMPLATES";
export const FETCH_CATEGORY_TEMPLATE_FAILURE =
  "@FETCH_CATEGORY_TEMPLATE_FAILURE";
export const SET_CATEGORY_TEMPLATE = "@SET_CATEGORY_TEMPLATE";
export const FETCH_CATEGORIES = "@FETCH_CATEGORIES";
export const FETCH_CATEGORIES_FAILURE = "@FETCH_CATEGORIES_FAILURE";
export const SET_CATEGORIES = "@SET_CATEGORIES";
export const SET_IS_CUSTOM_TEMPLATE = "@SET_IS_CUSTOM_TEMPLATE";
export const SET_CAMPAIGN_TYPE = "@SET_CAMPAIGN_TYPE";
export const SET_AUDIENCE_DATA = "@SET_AUDIENCE_DATA";
export const FETCH_WHATSAPP_CAMPAIGN = "@FETCH_WHATSAPP_CAMPAIGN";
export const SET_WHATSAPP_CAMPAIGN_DATA = "@SET_WHATSAPP_CAMPAIGN_DATA";
export const SET_WHATSAPP_CAMPAIGN_DATA_FAILURE =
  "@SET_WHATSAPP_CAMPAIGN_DATA_FAILURE";
