import React from "react";

import { Toast } from "@my-scoot/component-library-legacy";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";

import { useNotifications } from "utils/hooks";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

export const useStyles = ({ isMobile }) =>
    makeStyles((theme) => ({
        root: {
            position: "fixed",
            bottom: 60,
            right: isMobile ? "16px" : "24px",
            left: isMobile ? "16px" : "auto",
            zIndex: 111111,
        },

        list: {
            display: "flex",
            flexDirection: "column",
        },

        toastContainer: {
            margin: isMobile ? "8px auto 8px auto" : "8px 0 8px auto",
            width: "fit-content",
            transition: "0.2s ease all",

            cursor: "pointer",

            "& *": {
                fontFamily: "unset",
            },
        },
        toastRoot: {
            height: "fit-content",
        },
    }));

const NotificationPopup = (props) => {
    const isMobile = useMediaQuery("(max-device-width: 767px)");
    const styles = useStyles({ isMobile })();

    const { notificationList } = useNotifications();

    return (
        <div className={styles.root}>
            <div className={styles.list}>
                {Object.values(notificationList).map((item) => (
                    <div className={styles.toastContainer} key={item.id}>
                        <Toast
                            toastClassName={styles.toastRoot}
                            {...item.props}
                            onDelete={item.onDelete}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default withComponentLibraryTheme(NotificationPopup);
