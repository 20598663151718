import { resources } from "data";
import { STATUS } from "./whatsappBroadcast.constants";
import { whatsappCampaignStatusChoices } from "./list/constants";
import { whatsapp_broadcasts_apis } from "./whatsappBroadCast.api";
import { matchPath } from "react-router";
import {
  WHATSAPP_TEMPLATES_ROUTE_IDS,
  WHATSAPP_TEMPLATES_ROUTE_KEYS,
} from "features/WhatsappTemplate/WhatsappTemplate.constants";

const whatsapp_broadcast_resource_apis = {
  [WHATSAPP_TEMPLATES_ROUTE_IDS[WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list]]:
    ({ pathname }) => {
      const matchResults = matchPath(pathname, {
        path: WHATSAPP_TEMPLATES_ROUTE_IDS[
          WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list
        ],
      });
      return {
        api_end_point: whatsapp_broadcasts_apis.list_custom_creator_templates,
        queries: {
          approval_status: matchResults.params.id,
        },
      };
    },
  [resources.whatsapp_campaigns]: ({ params, urlParams }) => {
    return {
      api_end_point: whatsapp_broadcasts_apis.list_campaigns,
      queries: {
        approval_status: params.filter.segment_uid,
        status:
          Number(urlParams.get(STATUS)) ||
          whatsappCampaignStatusChoices.Completed,
      },
    };
  },
};

export default whatsapp_broadcast_resource_apis;
