import React from "react";
import { Prompt } from "react-router-dom";
import style from "./RouteLeaving.module.css";

import ExlyModal from "common/Components/ExlyModal";

{
  /*
RouteLeavingPrompt component is Used to prompt the user before navigating away 
from a page when in a state that should prevent the user
from navigating away (like a form is half-filled out)
*/
}
export class RouteLeavingPrompt extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) => {
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );
  };

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  attemptNavigate = (lastLocation) => {
    const { confirmedNavigation } = this.state;

    const { shouldBlockNavigation } = this.props;

    if (
      !confirmedNavigation &&
      lastLocation &&
      !shouldBlockNavigation(lastLocation)
    ) {
      this.setState(
        {
          confirmedNavigation: true,
        }
        // keeping this for now, to remove if working fine after 31/10/2023
        // () => {
        //   // Navigate to the previous blocked location with your navigate function
        //   if (window.location.pathname === lastLocation.pathname) {
        //     window.location.reload();
        //   } else {
        //     window.location = `${window.location.origin}/#${lastLocation.pathname}`;
        //   }
        // }
      );
    }
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { lastLocation } = this.state;
      const { shouldBlockNavigation } = this.props;

      if (shouldBlockNavigation(lastLocation)) {
        return;
      }

      this.attemptNavigate(lastLocation);
    });

  componentDidUpdate() {
    const { lastLocation } = this.state;
    if (this.props.saveComplete) {
      this.attemptNavigate(lastLocation);
    }
  }

  render() {
    const {
      when,
      primaryAction,
      secondaryAction,
      handleClose,
      disclaimer,
      primaryBtnText,
      open = false,
    } = this.props;

    const { modalVisible, lastLocation } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <ExlyModal
          title="Wait up!"
          open={modalVisible || open}
          onClose={() => {
            if (handleClose) handleClose();
            this.closeModal();
          }}
          modal_props={{
            modalPaperClassName: style.modalPaperClassName,
          }}
          mobile_modal_props={{
            secondaryAction: {
              label: "Leave anyway",
              onClick: () => secondaryAction(lastLocation),
              className: style.mobileModalFooter,
            },
          }}
          secondaryBtnText="Leave anyway"
          onSecondaryBtnClick={() => {
            secondaryAction(lastLocation);
          }}
          primaryBtnText={primaryBtnText || "Save"}
          onPrimaryBtnClick={() => {
            primaryAction && primaryAction();
            this.closeModal();
          }}
        >
          <div>
            <div className={style.formDesc}>
              {disclaimer ||
                `You’ll lose your progress if you go back! We’d suggest you ‘Save’
              this for once, so that it gets registered and your progress is
              saved. You can edit this later on.`}
            </div>
          </div>
        </ExlyModal>
      </>
    );
  }
}
export default RouteLeavingPrompt;
