import React from "react";
import Drawer from "@material-ui/core/Drawer";
import RightPanel from "./RightPanel";
import style from "./photoWidgetV2.module.css";
import {
  Button,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import constants from "constants/constants";
import { useNotifications } from "utils/hooks";
import { is_empty } from "utils/validations";
import classnames from "classnames";

const DEFAULT_IMAGE = constants.default_schedule_image;
const MERCHANDISE_DEFAULT =
  "https://images.unsplash.com/photo-1462392246754-28dfa2df8e6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c3RvcmV8ZW58MHx8MHx8&auto=format&fit=crop&w=1470&q=80";

export default function PhotoWidgetV2({
  buttonText,
  height,
  width,
  className,
  query,
  onChange,
  selected,
  showButton = true,
  scheduleType,
  aspect,
  disabled,
  defaultPlaceholderImg,
  maxSizeConstraints = {},
  placeHolderClickable = false,
  uploadViaOptimization = false,
  sectionName,
}) {
  const { notify } = useNotifications();
  const checkImageSizeExceeds = (e) => {
    if (e.target.files[0].size > maxSizeConstraints.value) {
      notify(
        `Error: File size is greater than ${maxSizeConstraints.label}`,
        "error"
      );
      setShowRightPanel(false);
      return true;
    } else return false;
  };

  const checkImageSizeExceedsWithConstarints = !is_empty(maxSizeConstraints)
    ? checkImageSizeExceeds
    : () => {};
  const defaultPlaceholder =
    defaultPlaceholderImg ||
    (selected || scheduleType === constants.scheduleTypesId.merchandise
      ? MERCHANDISE_DEFAULT
      : DEFAULT_IMAGE);
  const [showRightPanel, setShowRightPanel] = React.useState(false);
  const [crop] = React.useState({
    unit: "%",
    aspect,
    width: 100,
    ...(aspect ? {} : { height: 100 }),
  });

  const handleClick = () => setShowRightPanel(!showRightPanel);

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <div className={className}>
        <div
          className={classnames(
            style.photoWidgetImageHolder,
            placeHolderClickable && style.placeHolderClickableStyle,
            disabled && style.disabledStyle
          )}
          style={{
            backgroundImage: `url(${selected || defaultPlaceholder})`,
            height: height,
            width: width,
          }}
          {...(placeHolderClickable && { onClick: handleClick })}
        >
          {showButton && (
            <Button
              color="subtleSecondary"
              size="thin"
              onClick={handleClick}
              className="d-flex align-items-center text-dark"
              disabled={disabled}
            >
              <AddAPhoto className="mr-2" /> {buttonText}
            </Button>
          )}
        </div>
      </div>

      {showRightPanel && (
        <Drawer
          open
          anchor="right"
          // ref={this.ref}
          variant="temporary"
          onEscapeKeyDown={() => setShowRightPanel(false)}
          onBackdropClick={() => setShowRightPanel(false)}
        >
          <RightPanel
            query={query}
            minWidth={height}
            minHeight={width}
            id="photo"
            radius={3}
            crop={crop}
            getUrl={(url) => {
              onChange(url);
              setShowRightPanel(false);
            }}
            checkImageSizeExceeds={checkImageSizeExceedsWithConstarints}
            uploadViaOptimization={uploadViaOptimization}
            sectionName={sectionName}
          />
        </Drawer>
      )}
    </ThemeProvider>
  );
}

PhotoWidgetV2.defaultProps = {
  buttonText: "Change Photo",
  query: "mountains",
  onChange: () => {},
  sectionName: "",
};
